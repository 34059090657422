*, *::before, *::after {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    max-width: 41em;
    margin: 1em auto !important;
    padding: 0 1em;
}

#err-msg{
    color: red;
}

button,input[type=file] {
  cursor: pointer;
}

:is(button, input[type="file"])+*{
    display: inline-block;
}

:is(button, input[type="file"])+*::before{
    content: " ";
    display: block;
    height:.7em;
    width: .7em;
    margin: 0 .3em;
    border-radius: .1em;
}

:is(button, input[type="file"])[disabled]+*::before{
    animation: rot 1s linear infinite;
    border: 1px solid #444;
}

@keyframes rot{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(359deg);
    }
}

#upload-cert{
    height: 5em;
    position: relative;
    background: #eee;
    border: dashed 1px #444;
}

#upload-cert.active{
    background: #ddd;
    border: dashed 1px #222;
}

#upload-cert > *{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-container{
    margin-top: 2em;
}

.center-container > *{
    max-width: 25em;
    margin: 1em auto;
}

.inline-img{
    max-height:1.5em;
    display:inline;
    position:relative;
    top:.4em;
}

button#download{
    font-size: 1.1em;
    margin: .1em;
}

/* hide the arrow on a details elem */
summary {list-style: none}
summary::-webkit-details-marker {display: none; }

summary::after {
    content: "(?)";
    margin-left: 1ex;
    cursor: pointer;
}
